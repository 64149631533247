import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import { rhythm } from '../utils/typography'
import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rhythm(1.5)};
  margin-top: ${rhythm(1)};

  p {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.postTitleColor
        : LIGHT_THEME.postTitleColor};
    margin: 0;
  }

  img {
    margin-bottom: 0;
  }
`

function Bio() {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-picture-art.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <Wrapper>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Hey. I'm <strong>{author}</strong>.
        <br />I write anything to help others.
      </p>
    </Wrapper>
  )
}

export default Bio
